<template>
    <section class="talleres">
        <!-- Title -->
        <v-container>
            <v-row align="end" justify="center">
                <v-col cols="12" class="mb-8">
                    <h1
                        class="
                            text-h3 text-center text-uppercase
                            font-weight-bold
                            talleres-title
                        "
                    >
                        Summer School
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row align="start" justify="center">
                <v-col cols="12" sm="8" md="6">
                    <v-card color="white" rounded="lg" elevation="4" class="pa-4">
						<v-card-text>
							<p class="text-body-1 indigo--text text--darken-3">
								Dirigido a niños y niñas de 2 a 7 años de edad, una linda instancia para sociabilizar, aprender, jugar y divertirse por horas.
							</p>
							<p class="text-body-1 indigo--text text--darken-3">
								En nuestro Summer School tenemos actividades y talleres diarios que le permitirá a los niños fortalecer múltiples áreas como: coordinación, concentración, creatividad, atención, motricidad gruesa, sentimientos y emociones, destreza física, tolerancia, estimular la comunicación, etc. 
							</p>
							<p class="text-body-1 indigo--text text--darken-3">
								No te pierdas estas entretenidas actividades. 
							</p>
						</v-card-text>
					</v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row align="start" justify="space-around">
                <v-col cols="12" sm="6" md="5" class="mt-10">
                    <!-- Taller de Ciencias -->
                    <taller-ciencias />
                </v-col>
                <v-col cols="12" sm="6" md="5" class="mt-10">
                    <!-- Taller de Cocina -->
                    <taller-cocina />
                </v-col>
                <v-col cols="12" sm="6" md="5" class="mt-10">
                    <!-- Taller de Arte y Manualidades -->
                    <taller-arte-manualidades />
                </v-col>
                <v-col cols="12" sm="6" md="5" class="mt-10">
                    <!-- Taller de Reciclado -->
                    <taller-reciclado />
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import TallerCiencias from '../components/Talleres/TallerCiencias.vue';
import TallerCocina from '../components/Talleres/TallerCocina.vue';
import TallerArteManualidades from '../components/Talleres/TallerArteManualidades.vue';
import TallerReciclado from '../components/Talleres/TallerReciclado.vue';

export default {
    components: {
        TallerCiencias,
        TallerCocina,
        TallerArteManualidades,
        TallerReciclado,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.talleres {
    margin-bottom: 250px;
}

$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}
.talleres-title {
    text-shadow: 0px 4px 3px $official-red;
}
.word-break {
    word-break: normal;
}
</style>
