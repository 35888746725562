<template>
    <v-card
        color="green darken-3"
        rounded="lg"
        elevation="4"
        class="pa-4"
        id="card-two"
    >
        <v-card-title
            class="
                text-uppercase text-h5
                font-weight-bold
                official-yellow--text
                word-break
            "
            >Cocina
        </v-card-title>
        <v-card-text v-if="!showTallerCocina">
            <p class="text-body-1 white--text">
                Los ni&ntilde;os peque&ntilde;os son naturalmente curiosos del
                ...
            </p>
        </v-card-text>
        <v-card-actions>
            <v-btn
                text
                block
                color="yellow accent-2"
                class="text-end"
                @click="showTallerCocina = !showTallerCocina"
            >
                {{ showTallerCocina ? 'cerrar' : 'ver m&aacute;s' }}
                <v-spacer></v-spacer>
                <v-icon medium>
                    {{
                        showTallerCocina ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}
                </v-icon>
            </v-btn>
        </v-card-actions>
        <v-expand-transition>
            <div v-show="showTallerCocina">
                <v-divider class="white"></v-divider>
                <v-card-text>
                    <p class="text-body-1 white--text">
                        Los ni&ntilde;os peque&ntilde;os son naturalmente
                        curiosos del mundo que los rodea. Ense&ntilde;ar ciencia
                        a los ni&ntilde;os en edad preescolar puede aumentar
                        este interés y ayudar a los m&aacute;s peque&ntilde;os a
                        que aprendan a explorar su entorno.
                    </p>
                    <p class="text-body-1 white--text">
                        Las actividades pr&aacute;cticas de ciencia, los
                        experimentos y los proyectos permiten a los niños hacer
                        descubrimientos sobre fen&oacute;menos naturales,
                        actividades al aire libre y dem&aacute;s conceptos
                        cient&iacute;ficos.
                    </p>
                </v-card-text>
                <v-card-text>
                    <h3 class="text-xs-h5 white--text font-weight-light mb-3">
                        Objetivos del Taller:
                    </h3>
                    <p v-for="(item, i) in tallerCocina" :key="i">
                        <v-icon class="white--text mr-1"
                            >mdi-chevron-right</v-icon
                        >
                        <span class="white--text">{{ item.text }}</span>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="yellow accent-2"
                        class="text-end"
                        @click="showTallerCocina = !showTallerCocina"
                    >
                        cerrar

                        <v-icon medium>
                            {{
                                showTallerCocina
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                            }}
                        </v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-dialog
                        v-model="showDialog"
                        max-width="640px"
                        transition="fab-transition"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="yellow darken-1"
                                block
                                v-bind="attrs"
                                v-on="on"
                            >
                                Solicitar Informaci&oacute;n
                            </v-btn>
                        </template>
                        <v-container>
                            <v-row justify="end" class="white">
                                <v-btn
                                    color="white"
                                    @click.stop="dialogMethod()"
                                    icon
                                >
                                    <v-icon color="red darken-1"
                                        >mdi-close</v-icon
                                    >
                                </v-btn>
                            </v-row>
                            <v-row>
                                <contact-form
                                    taller="Cocina"
                                    color="white"
                                    alert="red--text text--darken-1"
                                    heading="text-h5 red--text text--darken-1 text-uppercase"
                                    :theme="false"
                                    loader="green"
                                />
                            </v-row>
                        </v-container>
                    </v-dialog>
                </v-card-actions>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script scoped>
import ContactForm from '../ContactForm.vue';
export default {
    components: { ContactForm },
    data: () => ({
        showTallerCocina: false,
        tallerCocina: [
            {
                text: 'Cocinar de forma entretenida.',
            },
            {
                text: 'Adquirir hábitos de higiene y alimentación saludable.',
            },
            {
                text: 'Reconocimiento de palabras.',
            },
            {
                text: 'Comienzan a trabajar la lectura a través de símbolos.',
            },
        ],
        showDialog: false,
    }),
    methods: {
        dialogMethod() {
            this.showDialog = !this.showDialog;
        },
    },
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}

.word-break {
    word-break: normal;
}
</style>
