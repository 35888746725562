<template>
    <v-card
        color="purple darken-3"
        rounded="lg"
        elevation="4"
        class="pa-4"
        id="card-two"
    >
        <v-card-title
            class="
                text-uppercase text-h5
                font-weight-bold
                official-yellow--text
                word-break
            "
            >Reciclado
        </v-card-title>
        <v-card-text v-if="!showTallerReciclado">
            <p class="text-body-1 white--text">
                En nuestro taller los niños realizaran actividades ...
            </p>
        </v-card-text>
        <v-card-actions>
            <v-btn
                text
                block
                color="yellow accent-2"
                class="text-end"
                @click="showTallerReciclado = !showTallerReciclado"
            >
                {{ showTallerReciclado ? 'cerrar' : 'ver m&aacute;s' }}
                <v-spacer></v-spacer>
                <v-icon medium>
                    {{
                        showTallerReciclado
                            ? 'mdi-chevron-up'
                            : 'mdi-chevron-down'
                    }}
                </v-icon>
            </v-btn>
        </v-card-actions>
        <v-expand-transition>
            <div v-show="showTallerReciclado">
                <v-divider class="white"></v-divider>
                <v-card-text>
                    <p class="text-body-1 white--text">
                        En nuestro taller los ni&ntilde;os realizaran
                        actividades l&uacute;dicas y educativas que fomenten el
                        respeto por el medio ambiente y la valoraci&oacute;n d e
                        los materiales desechables como materias primas que
                        pueden y deben ser reutilizadas.
                    </p>
                </v-card-text>
                <v-card-text>
                    <h3 class="text-xs-h5 white--text font-weight-light mb-3">
                        Objetivos del Taller:
                    </h3>
                    <p v-for="(item, i) in tallerReciclado" :key="i">
                        <v-icon class="white--text mr-1"
                            >mdi-chevron-right</v-icon
                        >
                        <span class="white--text">{{ item.text }}</span>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="yellow accent-2"
                        class="text-end"
                        @click="showTallerReciclado = !showTallerReciclado"
                    >
                        cerrar

                        <v-icon medium>
                            {{
                                showTallerReciclado
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                            }}
                        </v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-dialog
                        v-model="showDialog"
                        max-width="640px"
                        transition="fab-transition"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="yellow darken-1"
                                block
                                v-bind="attrs"
                                v-on="on"
                            >
                                Solicitar Informaci&oacute;n
                            </v-btn>
                        </template>
                        <v-container>
                            <v-row justify="end" class="white">
                                <v-btn
                                    color="white"
                                    @click.stop="dialogMethod()"
                                    icon
                                >
                                    <v-icon color="red darken-1"
                                        >mdi-close</v-icon
                                    >
                                </v-btn>
                            </v-row>
                            <v-row>
                                <contact-form
                                    taller="Reciclado"
                                    color="white"
                                    alert="red--text text--darken-1"
                                    heading="text-h5 red--text text--darken-1 text-uppercase"
                                    :theme="false"
                                    loader="purple"
                                />
                            </v-row>
                        </v-container>
                    </v-dialog>
                </v-card-actions>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script scoped>
import ContactForm from '../ContactForm.vue';
export default {
    components: { ContactForm },
    data: () => ({
        showTallerReciclado: false,
        tallerReciclado: [
            {
                text: 'Realizar actividades lúdicas y educativas.',
            },
            {
                text: 'Fomentar el respeto por el medio ambiente',
            },
            {
                text: 'Valoración de materiales desechables como materias primas.',
            },
            {
                text: 'Comprensión de materiales para reutilizar.',
            },
        ],
        showDialog: false,
    }),
    methods: {
        dialogMethod() {
            this.showDialog = !this.showDialog;
        },
    },
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}

.word-break {
    word-break: normal;
}
</style>
